/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Berlin"), React.createElement("p", null, "Pour se loger à Berlin, les quartiers les plus centraux et les plus animés sont :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/de/berlin/mitte.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Mitte"), " : le centre historique de Berlin, avec la porte de Brandebourg et l’île aux musées."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/de/berlin/kreuzberg.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Kreuzberg"), ", au sud : quartier turc connu pour ses restaurants, bars et clubs."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/de/berlin/tiergarten.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Tiergarten"), ", à l’ouest : le grand parc du centre de Berlin et le Kulturforum, près de Potsdamer Platz."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/de/berlin/charlottenburg.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Charlottenbourg"), ", à l’ouest de Tiergarten : quartier célèbre pour son château, l’église du Souvenir et les magasins de Ku’damm."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
